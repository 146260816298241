.navbar-light.bg-transparent {
  background-color: transparent !important;
  transition: background-color 0.3s ease;
}

.navbar-dark.bg-dark {
  background-color: #000000bb !important;
  transition: background-color 0.3s ease;
}

.nav-link {
  font-size: 14px;
  font-family: "Sora" sans-serif;
  font-weight: bold;
  padding: 10px 30px;
  /* margin-right: 10px; */
  height: 40px;
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.nav-item.active .nav-link {
  border-bottom: 2px solid #fff;
}
/* #93C6CE */
.nav-link:hover {
  background-image: linear-gradient(45deg, #93c6ce, #93c6ce50);
  transition: 0.3s ease-in;
}

@media (max-width: 992px) {
  .nav-link {
    cursor: pointer;
  }
  .nav-item .nav-link {
    background-color: #212529;
    width: 100%;
  }
  .nav-item.active .nav-link {
    border-bottom: none;
    background-color: #212529;
    width: 100%;
  }
  .navbar-light.bg-transparent {
    background-color: #fff;
  }

  .navbar-dark.bg-dark {
    background-color: #fff;
  }

  .navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
  }
}

@media (min-width: 992px) {
  .navbar {
    background-color: transparent !important;
  }
}
