.mil-illustration {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mil-image-frame img {
  width: 100%;
  height: auto;
}

.mil-slider-button {
  cursor: pointer;
}

/* Additional custom styles to match the original design */
.mil-suptitle {
  font-size: 1.2em;
  font-weight: bold;
}

.mil-upper {
  text-transform: uppercase;
}

.mil-up {
  margin-top: 20px;
}

.mil-mb-30 {
  margin-bottom: 30px;
}

.mil-mb-40 {
  margin-bottom: 40px;
}

.mil-mb-60 {
  margin-bottom: 60px;
}

.mil-marker {
  background-color: yellow;
  padding: 0 5px;
}
