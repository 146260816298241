.mil-p-0-30 {
  padding: 0 30px;
}

.mil-background-grid {
  /* Your background grid styles */
}

.mil-softened {
  /* Your softened styles */
}

.mil-up {
  /* Your up styles */
  transition: all 0.3s ease;
}

.mil-mb-90 {
  margin-bottom: 90px;
  transition: margin-bottom 0.3s ease;
}

.mil-suptitle {
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 30px;
  transition: margin-bottom 0.3s ease, font-size 0.3s ease;
}

.mil-mb-30 {
  margin-bottom: 30px;
  transition: margin-bottom 0.3s ease;
}

.mil-mb-40 {
  margin-bottom: 40px;
  transition: margin-bottom 0.3s ease;
}

.mil-marker {
  /* Your marker styles */
}

.mil-right {
  float: right;
}

.mil-counter {
  /* Your counter styles */
}

/* Add more transition effects as needed */

.mil-p-0-90 {
  padding: 0 90px;
  transition: padding 0.3s ease;
}

.mil-up {
  transition: all 0.3s ease;
}

.mil-mb-30,
.mil-mb-40,
.mil-mb-60 {
  transition: margin-bottom 0.3s ease;
}

.mil-icon-box {
  transition: transform 0.3s ease;
}

.mil-icon-box:hover {
  transform: translateY(-5px);
}
