.mil-avatar2 {
  position: relative;
  overflow: hidden;
  border-radius: 25%;
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
}

.mil-avatar2 img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  object-position: center;
}
