.content-image {
  position: relative;
  background: url("../../utils/Images/Footer/footer.jpg") center/cover no-repeat;
  z-index: 1;
  filter: brightness(0.5);
}

.content-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(8px) !important;
  z-index: -1;
}

.text-white {
  color: #fff !important;
}

.text-reset:hover {
  color: #ffcc00 !important;
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.footer img {
  max-height: 200px;
}

@media (max-width: 992px) {
  .footer img {
    display: none;
  }
}
